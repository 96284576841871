import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"

const ProjectsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allProjectsJson(sort: { fields: position, order: DESC }) {
        edges {
          node {
            id
            title
            description
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 640, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout className="subpage">
      <SEO
        title="Realizacje"
        description="Poniżej prezentujemy projekty, które pokazują przekrój naszych możliwości. W projektach w skali architektonicznej kładziemy nacisk na trwałą i solidną konstrukcję. Dbamy o mobilność mebli przeznaczonych na targi i o funkcjonalność wyposażenia lokali usługowych. Troszczymy się o każdy szczegół."
      />
      <Container fluid>
        <section className="white-bg">
          <h2 className="section-header">Nasze realizacje</h2>
          <Container fluid>
            <Row>
              <Col className="d-flex projects">
                <p>
                  Poniżej prezentujemy projekty, które pokazują przekrój naszych możliwości. W projektach w skali architektonicznej kładziemy nacisk na trwałą i solidną konstrukcję. Dbamy o mobilność mebli przeznaczonych na targi i o funkcjonalność wyposażenia lokali usługowych. Troszczymy się o każdy szczegół.
                </p>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <Col className="d-flex projects">
                {data.allProjectsJson.edges.map(
                  el =>
                    el.node.image && (
                      <div className="project">
                        <Img fluid={el.node.image.childImageSharp.fluid} />
                        <div className="caption">
                          <h3>
                            <Link to={"realizacje/" + el.node.slug}>
                              {el.node.title}
                            </Link>
                          </h3>
                        </div>
                        <Link
                          to={"/realizacje/" + el.node.slug}
                          className="block-link"
                        ></Link>
                      </div>
                    )
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </Layout>
  )
}

export default ProjectsPage
